import React, { forwardRef } from 'react'

import Text from '@/UI/Text'

const DEFAULT_TAG = 'h1'

const Heading = (props, forwardedRef) => {
  const {
    size = 1,
    ...textProps
  } = props

  const textSize = {
    0: {
      '@initial': 'sliding-txt-mobile',
      '@md': 'sliding-txt'
    },
    1: {
      '@initial': 'h1-mobile',
      '@md': 'h1-mid',
      '@lg': 'h1',
    },
    2: {
      '@initial': 'h2-mobile',
      '@md': 'h2'
    },
    s1: {
      '@initial': 'subtitle-1-mobile',
      '@md': 'subtitle-1'
    },
    s2: {
      '@initial': 'subtitle-2-mobile',
      '@md': 'subtitle-2'
    },
    news: {
      '@initial': 'news-title-mobile',
      '@md': 'news-title'
    },
  }

  const textCss = {
    0: {
      fontWeight: '$1', lineHeight: '$sliding-txt-mobile', '@md': { lineHeight: '$sliding-txt' }
    },
    1: {
      fontWeight: '$1', lineHeight: '$h1-mobile', '@md': { lineHeight: '110%' },
    },
    2: {
      fontWeight: '$1', lineHeight: '$h2-mobile', '@md': { lineHeight: '$h2' }
    },
    s1: {
      fontWeight: '$1', lineHeight: '$subtitle-1-mobile', '@md': { lineHeight: '$subtitle-1' }
    },
    s2: {
      fontWeight: '$1', lineHeight: '$subtitle-2-mobile', '@md': { lineHeight: '$subtitle-2' }
    },
    news: {
      fontWeight: '$1', lineHeight: '$news-title-mobile', '@md': { lineHeight: '$news-title' }
    },
  }

  return (
    <Text
      className="Heading"
      as={DEFAULT_TAG}
      {...textProps}
      ref={forwardedRef}
      size={textSize[size]}
      css={{
        display: 'block',
        textAlign: 'left',
        width: '100%',
        fontVariantNumeric: 'proportional-nums',
        wordBreak: 'break-word',
        ...textCss[size],
        ...props.css
      }}
    />
  )
}

export default forwardRef(Heading)
